import React from 'react';
import { MultiInsuranceInput } from './MultiInsuranceInput';
import { getIterable } from '../../../../../util/array';

export const InsurancesList = (props) => {
  const {
    paymentType,
    getPayerlist,
    onPaymentInfoChange,
    onInsuranceTypeChange,
    setFieldError,
    isInsuranceValidationLoading,
    getSelectedPayer,
    availableInsuranceTypes,
    insuranceType,
    errors,
    insuranceInfo,
    isInsurancePayment,
    totalNoOfInsurances,
    setCurrentInsuranceIndex,
    onRemoveInsuranceDetail,
    setRequireFormDataFilledStatus,
  } = props;

  return getIterable(totalNoOfInsurances).map((_, insuranceIndex) => (
    <MultiInsuranceInput
      errors={errors}
      value={paymentType}
      availableInsuranceTypes={availableInsuranceTypes}
      payerlist={getPayerlist(insuranceIndex)}
      onChange={onPaymentInfoChange}
      onInsuranceTypeChange={onInsuranceTypeChange}
      setFieldError={setFieldError}
      isInsurancePayment={isInsurancePayment}
      onRemoveInsuranceDetail={onRemoveInsuranceDetail}
      setCurrentInsuranceIndex={setCurrentInsuranceIndex}
      insuranceIndex={insuranceIndex}
      insuranceType={insuranceType[insuranceIndex]}
      insuranceInfo={insuranceInfo[insuranceIndex]}
      selectedPayer={getSelectedPayer(insuranceIndex)}
      setRequireFormDataFilledStatus={setRequireFormDataFilledStatus}
      // insurance verification related states
      isInsuranceValidationLoading={isInsuranceValidationLoading}
      key={insuranceIndex}
    />
  ));
};
