import * as Yup from 'yup';

import * as Regex from 'util/regex';
import { getString } from 'util/lang';
import regexPattern from 'constants/regexPattern';
import { PatientGuardianInfoKeys } from 'constants/patientInfo';
import { hasConsectiveChar, hasExtraSpace } from 'util/string';

export const basicPatientInfoFieldNames = {
  firstName: 'firstName',
  middleName: 'middleName',
  lastName: 'lastName',
  birthYear: 'birthYear',
  birthMonth: 'birthMonth',
  birthDay: 'birthDay',
  ethnicity: 'ethnicity',
  race: 'race',
  gender: 'gender',
  primaryLanguage: 'primaryLanguage',
  mobileNo: 'mobileNo',
  email: 'email',
  referredBy: 'referredBy',
  confirmEmail: 'confirmEmail',
};

export const validateGuardianInfo = (patientDetails) => {
  let errors = {};

  PatientGuardianInfoKeys.forEach((key) => {
    let value = patientDetails[key];

    try {
      guardianInfoYupSchema()[key].validateSync(value);
      errors[key] = null;
    } catch (err) {
      errors[key] = err.message;
    }
  });

  return { errors };
};

export const ValidatePatientInfo = (
  patientDetails,
  isScreeningPage = false,
  waitlistValidationFields = [],
) => {
  let errors = {};

  Object.keys(patientDetails).forEach(async (key) => {
    let value = patientDetails[key];

    try {
      // Note: Composite validation done this way because PatientInfoYupSchema is not YUP object, but its plain JS object
      if (key === 'mobileNo' && patientDetails.phoneOptOut) {
        errors[key] = null;
        return;
      } else if (PatientGuardianInfoKeys.includes(key)) {
        return;
      }

      if (waitlistValidationFields.length > 0) {
        WaitlistPatientBasicInfoYupSchema(waitlistValidationFields)[
          key
        ].validateSync(value);
      } else if (isScreeningPage) {
        PatientBasicInfoYupSchema(isScreeningPage)[key].validateSync(value);
      } else PatientInfoYupSchema()[key].validateSync(value);

      errors[key] = null;
    } catch (err) {
      errors[key] = err.message;
    }
  });

  return {
    errors,
  };
};

const YearRegx = Regex.ValidBirthYear();

const PatientBasicInfoYupSchema = (isScreeningPage) => {
  const emailAndPhoneYup = EmailAndPhoneYupSchema(isScreeningPage);

  return {
    firstName: Yup.string()
      .required(getString('firstNameRequired'))
      .matches(regexPattern.nameField, getString('firstNameValidationMessage')),

    lastName: Yup.string()
      .required(getString('lastNameRequired'))
      .matches(regexPattern.nameField, getString('lastNameValidationMessage')),

    middleName: Yup.string().matches(regexPattern.nameField, {
      message: getString('middleNameValidationMessage'),
      excludeEmptyString: true,
    }),

    birthMonth: Yup.string().required(getString('monthRequired')),

    birthDay: Yup.string()
      .required(getString('dayRequired'))
      .matches(/^([1-2]?[0-9]|3[0-1])$/, getString('dayValidationMessage')),

    birthYear: Yup.string()
      .required(getString('yearRequired'))
      .matches(YearRegx, getString('yearValidationMessage')),

    gender: Yup.string().nullable().required(getString('genderRequired')),

    zipCode: Yup.string()
      .required(getString('zipRequired'))
      .min(5, getString('zipValidationMessageMin'))
      .max(5, getString('zipValidationMessageMax'))
      .matches(/^[0-9]+$/, { message: getString('zipValidationMessage') }),

    race: Yup.string().nullable().required(getString('raceRequired')),

    ethnicity: Yup.string().nullable().required(getString('ethnicityRequired')),

    ...emailAndPhoneYup,
  };
};

const WaitlistEmailAndPhoneYupSchema = (waitlistValidationFields = []) => {
  const initialSchema = {
    email: Yup.string().email(getString('validEmailRequired')),
    mobileNo: Yup.string()
      .matches(/\W*([0-9]{3})-\W*([0-9]{3})-\W*([0-9]{4})/, {
        message: getString('mobileNumberValid'),
        excludeEmptyString: true,
      })
      .max(12, getString('mobileNumberValidationMessageMax')),
  };

  const reducedFields = waitlistValidationFields.reduce((acc, key) => {
    if (key === 'email') {
      acc[key] = acc[key].required(getString('emailRequired'));
    } else if (key === 'mobileNo') {
      acc[key] = acc[key].required(getString('phoneNumberRequired'));
    }

    return acc;
  }, initialSchema);

  return reducedFields;
};

const EmailAndPhoneYupSchema = (isScreeningPage) => {
  if (isScreeningPage) {
    return {
      mobileNo: Yup.string()
        .matches(/\W*([0-9]{3})-\W*([0-9]{3})-\W*([0-9]{4})/, {
          message: getString('mobileNumberValid'),
          excludeEmptyString: true,
        })
        .max(12, getString('mobileNumberValidationMessageMax')),
      email: Yup.string()
        .required(getString('emailRequired'))
        .email(getString('validEmailRequired')),
    };
  } else {
    return {
      mobileNo: Yup.string()
        .required(getString('mobileNumberRequired'))
        .min(12, getString('mobileNumberValidationMessageMin'))
        .matches(/\W*([0-9]{3})-\W*([0-9]{3})-\W*([0-9]{4})/, {
          message: getString('mobileNumberValidationMessage'),
          excludeEmptyString: true,
        })
        .max(12, getString('mobileNumberValidationMessageMax')),
      homePhoneNo: Yup.string()
        .matches(/\W*([0-9]{3})-\W*([0-9]{3})-\W*([0-9]{4})/, {
          message: getString('homePhoneNumberValid'),
          excludeEmptyString: true,
        })
        .min(12, getString('homePhoneNumberValidationMessage'))
        .max(12, getString('homePhoneNumberValidationMessage')),
      email: Yup.string().email(getString('validEmailRequired')),
    };
  }
};

const WaitlistPatientBasicInfoYupSchema = (waitlistValidationFields = []) => {
  const waitlistFieldsSchema = WaitlistEmailAndPhoneYupSchema(
    waitlistValidationFields,
  );

  return {
    firstName: Yup.string()
      .required(getString('firstNameRequired'))
      .matches(regexPattern.nameField, getString('firstNameValidationMessage')),

    lastName: Yup.string()
      .required(getString('lastNameRequired'))
      .matches(regexPattern.nameField, getString('lastNameValidationMessage')),

    middleName: Yup.string().matches(regexPattern.nameField, {
      message: getString('middleNameValidationMessage'),
      excludeEmptyString: true,
    }),

    birthMonth: Yup.string().required(getString('monthRequired')),

    birthDay: Yup.string()
      .required(getString('dayRequired'))
      .matches(/^([1-2]?[0-9]|3[0-1])$/, getString('dayValidationMessage')),

    birthYear: Yup.string()
      .required(getString('yearRequired'))
      .matches(YearRegx, getString('yearValidationMessage')),

    gender: Yup.string().nullable().required(getString('genderRequired')),

    zipCode: Yup.string()
      .required(getString('zipRequired'))
      .min(5, getString('zipValidationMessageMin'))
      .max(5, getString('zipValidationMessageMax'))
      .matches(/^[0-9]+$/, { message: getString('zipValidationMessage') }),

    race: Yup.string().nullable().required(getString('raceRequired')),

    ethnicity: Yup.string().nullable().required(getString('ethnicityRequired')),

    ...waitlistFieldsSchema,
  };
};

// Here schemas are created as a function so that they can be triggered when the languages change
const PatientInfoYupSchema = () => {
  const patientBasicInfoSchema = PatientBasicInfoYupSchema(false);

  return {
    ...patientBasicInfoSchema,

    emailOptOut: Yup.bool(false),
    phoneOptOut: Yup.bool(false),

    confirmEmail: Yup.string().email(getString('validConfirmEmailRequired')),

    referredBy: Yup.string().max(100, getString('referralValidationMessage')),

    physicianName: Yup.string()
      .nullable()
      .matches(/(^[A-Z.‘' -]+$)/i, {
        message: getString('nameCharacterValidationMessage'),
        excludeEmptyString: true,
      })
      .test(
        'has-extra-space',
        getString('nameSpaceValidationMessage'),
        function (value) {
          return !hasExtraSpace(value);
        },
      )
      .test(
        'has-repeat-char',
        getString('nameRepeatValidationMessage'),
        function (value) {
          return !hasConsectiveChar(value);
        },
      ),

    physicianPhoneNumber: Yup.string()
      .nullable()
      .test(
        'is-required',
        getString('mobileNumberValidationMessageMin'),
        function (value) {
          return !value || value.length >= 12;
        },
      )
      .matches(/\W*([0-9]{3})-\W*([0-9]{3})-\W*([0-9]{4})/, {
        message: getString('mobileNumberValidationMessage'),
        excludeEmptyString: true,
      })
      .max(12, getString('mobileNumberValidationMessageMax')),

    aptUnit: Yup.string().matches(regexPattern.addressField, {
      message: getString('aptUnitValidationMessage'),
      excludeEmptyString: true,
    }),

    city: Yup.string()
      .required(getString('cityRequired'))
      .matches(regexPattern.cityField, getString('cityValidationMessage')),

    state: Yup.string().required(getString('stateRequired')).nullable(),

    county: Yup.string().required(getString('countyRequired')).nullable(),

    homeAddress: Yup.string()
      .required(getString('homeAddressRequired'))
      .matches(
        regexPattern.addressField,
        getString('homeAddressValidationMessage'),
      ),

    addressFilledType: Yup.string()
      .nullable()
      .required(getString('addressFilledTypeRequired')),

    confirmationCode: Yup.string().nullable(true),

    acceptedTerms: Yup.object(),
    compressedSignature: Yup.string()
      .nullable()
      .required(getString('signatureRequired')),
    paymentType: Yup.string().nullable(),
    signatureUrl: Yup.string()
      .nullable()
      .required(getString('signatureRequired')),
    guardian: Yup.string(),
    iisConsent: Yup.bool(false),
    emailNotificationConsent: Yup.bool(false),
    sendEmail: Yup.bool(false),
    smsNotificationConsent: Yup.bool(false),
    marketingNotificationConsent: Yup.bool(),
    primaryLanguage: Yup.string(),
    idx: Yup.string().optional(),
  };
};

const guardianInfoYupSchema = () => ({
  guardianFirstName: Yup.string()
    .required(getString('firstNameRequired'))
    .matches(regexPattern.nameField, getString('firstNameValidationMessage')),

  guardianLastName: Yup.string()
    .required(getString('lastNameRequired'))
    .matches(regexPattern.nameField, getString('lastNameValidationMessage')),

  guardianMiddleName: Yup.string().matches(regexPattern.nameField, {
    message: getString('middleNameValidationMessage'),
    excludeEmptyString: true,
  }),
});

const PharmacyInsuranceValidationYupSchema = () => ({
  rxBin: Yup.string().test(
    'empty-check',
    getString('rxBinLengthValidationMessage'),
    (rxBin) => rxBin.length == 0 || rxBin.length == 6,
  ),
  rxPCN: Yup.string().nullable(),
  rxGroup: Yup.string().nullable(),
});

export const InsuranceDetailsValidation = (
  insuranceDetails,
  isPharmacyInsurance,
) => {
  let errors = {};
  Object.keys(insuranceDetails).forEach(async (key) => {
    let value = insuranceDetails[key];
    try {
      if (
        !!isPharmacyInsurance &&
        !!PharmacyInsuranceValidationYupSchema()[key]
      )
        PharmacyInsuranceValidationYupSchema()[key].validateSync(value);
      if (!!InsuranceDetailsYupSchema()[key])
        InsuranceDetailsYupSchema()[key].validateSync(value);
      errors[key] = null;
    } catch (err) {
      errors[key] = err.message;
    }
  });

  return {
    errors,
  };
};
const InsuranceDetailsYupSchema = () => ({
  company: Yup.string().required(getString('insuranceDetailsRequired')),

  insuranceType: Yup.string()
    .required(getString('insuranceTypeRequired'))
    .nullable(),

  insurancePlanType: Yup.string().required(
    getString('insurancePlanTypeRequired'),
  ),

  otherInsuranceCompany: Yup.string().required(
    getString('insuranceNameRequired'),
  ),

  memberID: Yup.string()
    .required(getString('memberIdRequired'))
    .matches(/(^$|^([a-z,A-Z,0-9]*\S*[0-9]+)+|^([0-9]+\S*[a-z,A-Z,0-9]+)+$)/, {
      message: getString('memberIdValidationMessage'),
    })
    .max(100, 'Member ID cannot exceed 100 characters')
    .test(
      'memberIDRepetition',
      'Member ID should not be repetitive or sequential numbers',
      function (value) {
        // @multilanguage
        const set = new Set(value.split(''));
        const setLength = set.size;

        if (setLength === 1) return false;

        const pattern = '0123456789';
        const reverse = pattern.split('').reverse().join('');

        if (pattern.includes(value) || reverse.includes(value)) return false;

        return true;
      },
    ),

  memberIDPrefix: Yup.string(),
  memberIDSuffix: Yup.string(),

  subscriberRelationship: Yup.string().required(
    getString('subscriberRelationshipRequired'),
  ),

  cardHolderFirstName: Yup.string().required(
    getString('cardHolderFirstNameRequired'),
  ),

  cardHolderMiddleName: Yup.string(),

  cardHolderLastName: Yup.string().required(
    getString('cardHolderLastNameRequired'),
  ),

  groupNumber: Yup.string().max(
    100,
    'Group Number cannot exceed 100 characters',
  ),

  coverageID: Yup.string(),

  paymentType: Yup.string(),
});

/**
 * Check if email and confirmEmail Id field value matches.
 *
 * @param {string} email : Email id of patient
 * @param {string} confirmEmail : Email id to confirm the email id.
 */
export const validateEmailWithConfirmEmail = (
  email,
  confirmEmail,
  emailConsent,
) => {
  if (!emailConsent) {
    return { confirmEmail: null };
  }
  if (!!email && email !== confirmEmail) {
    return { confirmEmail: getString('emailMatchRequired') };
  }
  return { confirmEmail: null };
};
