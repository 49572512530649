import React from 'react';
import { getString } from 'util/lang';
import AlertMessage from '../../../common/AlertMessage';
import styles from './PayAtTimeOfServiceInfoBox.module.scss';

/**
 *
 * @param {{
 *   selections: import('types').Selection[]
 * }} props
 * @returns
 */
const PayAtTimeOfService = (props) => {
  const { selections } = props;

  const message =
    selections.length === 1
      ? selections[0].subService.outOfPocketText // single service -> show message set in manager app
      : getString('outOfPocketMultiServiceNotice'); // multi service -> static message

  return (
    <section
      className="pay-at-time-of-service-info mt-2x"
      data-qa="patient-info-payAtTimeOfService"
    >
      {selections.length === 1 ? (
        <label>
          {getString('costOfService')}
          <span>${selections[0].subService.pricing}</span>
        </label>
      ) : (
        <table className={styles.paymentInfoTable}>
          <tbody>
            {selections.map((selection, idx) => (
              <tr className={styles.item} key={idx}>
                <td>{selection.service.name}:</td>
                <td className={styles.price}>
                  ${(selection.subService?.pricing ?? 0).toFixed(2)}
                </td>
              </tr>
            ))}
            <tr className={styles.total}>
              <td>{getString('totalCostOfService')}</td>
              <td className={styles.price}>
                $
                {selections
                  .reduce(
                    (sum, { subService }) => sum + subService?.pricing ?? 0,
                    0,
                  )
                  .toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      )}

      <AlertMessage
        dataqa="pay-at-time-of-service-alert"
        type="info"
        message={message}
        className="my-4x"
        isVisible={true}
      />
    </section>
  );
};

export default PayAtTimeOfService;
