import config from '../config';
import * as http from '../util/http';
import { intersectionBy } from 'lodash';

const InsuranceTypeMap = {
  153940000: 'MEDICARE',
  153940001: 'MEDICARE_ADVANTAGE',
  153940002: 'COMMERCIAL',
  153940003: 'MEDICAID',
};

/**
 * Fetch insurance company list
 *
 * @param {string} serviceId : guid
 * @param {keyof InsuranceTypeMap} insuranceTypeCode
 * @returns {import('types').InsuranceOrganization[]} data: List of insurance companies and their ids
 */
export async function fetchPayerListForSingleService(
  serviceId,
  insuranceTypeCode,
) {
  // TODO: use insuranceType directly during/after create application conversion
  const insuranceType = InsuranceTypeMap[insuranceTypeCode];

  if (insuranceTypeCode && !insuranceType) {
    console.error('invalid insurance type', insuranceTypeCode);
  }

  const url = `${config.entity.payerlist}/${serviceId}${insuranceType ? `?insuranceType=${insuranceType}` : ''}`;
  const { data } = await http.get(url);

  return data.data;
}

/**
 * Fetch insurance company list
 *
 * @param {string[]} serviceIds : guid
 * @param {keyof InsuranceTypeMap} insuranceTypeCode
 */
export async function fetchPayerList(serviceIds, insuranceTypeCode) {
  const payerLists = await Promise.all(
    serviceIds.map((serviceId) =>
      fetchPayerListForSingleService(serviceId, insuranceTypeCode),
    ),
  );

  const commonPayerList = intersectionBy(...payerLists, 'guid');

  return commonPayerList;
}
