import { ABNFormTable } from './components/ABNFormTable';
import { ABNFormTitle } from './components/ABNFormTitle';
import { ABNFormFooter } from './components/ABNFormFooter';
import { ABNFormHeader } from './components/ABNFormHeader';
import { ABNFormOptions } from './components/ABNFormOptions';
import { ABNFormSignature } from './components/ABNFormSignature';
import { ABNFormPagination } from './components/ABNFormPagination';
import { ABNFormAdditionalInfo } from './components/ABNFormAdditionalInfo';

import { ABNLang } from 'constants/dme';
import { customPick } from 'util/objectUtils';
import { validateFormData } from 'util/formValidator';
import { AbnFormSchema } from 'validator/productOrder';
import { concatenateWithSeparator } from 'util/string';

export const ABNForm = ({
  logoUrl,
  notifier,
  goBack,
  goNext,
  isMedicare,
  handleSubmit,
  isLastDocument,
  nextAbnLabel,
  previousLabel,
  orderItem,
  productOrder,
  primaryPayerName,
  signedOrderItemData,
  onChange,
  errors,
  setErrors,
}) => {
  const abnLang = isMedicare ? ABNLang.MEDICARE : ABNLang.NON_MEDICARE;
  const abnData = `dme.documentation.abnForm.${abnLang}`;

  const handleSubmission = () => {
    const { errors, isValid } = validateFormData(
      customPick(signedOrderItemData, [
        'abnSignedDate',
        'abnSignature',
        'orderItemId',
      ]),
      AbnFormSchema,
    );

    if (!isValid) {
      setErrors(errors);
      return;
    }
    if (isLastDocument) {
      handleSubmit();
    } else {
      goNext();
    }
    setErrors({});
  };

  return (
    <div className="wrapper">
      <div className="header">
        <div className="logo">
          <img src={logoUrl} alt="pharmacy_logo" />
        </div>
      </div>
      <div className="container-lg">
        <ABNFormHeader
          abnData={abnData}
          patientFirstname={productOrder.firstName}
          patientMiddleName={productOrder.middleName || ''}
          patientLastName={productOrder.lastName}
          identificationNumber={orderItem.abnIdentificationNumber}
          notifier={notifier}
        />

        <ABNFormTitle abnData={abnData} primaryPayerName={primaryPayerName} />

        <ABNFormTable
          abnData={abnData}
          productHcpcs={concatenateWithSeparator(
            orderItem.hcpcsName,
            orderItem.hcpcsShortDescription,
            '-',
          )}
          reason={orderItem.reasonMedicareMayNotPay}
          estimatedCost={orderItem.abnEstimatedCost}
        />

        <ABNFormOptions
          abnData={abnData}
          primaryPayerName={primaryPayerName}
          option1={orderItem.abnOption1}
          option2={orderItem.abnOption2}
          option3={orderItem.abnOption3}
        />

        <ABNFormAdditionalInfo
          abnAdditionalInfo={orderItem.abnAdditionalInformation}
          abnData={abnData}
        />

        <ABNFormSignature
          abnData={abnData}
          signedOrderItemData={signedOrderItemData}
          orderItemId={orderItem.orderItemId}
          onChange={onChange}
          errors={errors}
          setErrors={setErrors}
        />

        {isMedicare && <ABNFormFooter />}

        <ABNFormPagination
          goBack={goBack}
          goNext={goNext}
          handleSubmit={handleSubmit}
          isLastDocument={isLastDocument}
          nextAbnLabel={nextAbnLabel}
          previousLabel={previousLabel}
          handleSubmission={handleSubmission}
        />
      </div>
    </div>
  );
};
