import { sortObjectArraybyStringField } from '../util/array';
import {
  regimentDose,
  regimenTypes,
  regimenViewType,
} from '../constants/healthcareServices';

const mapServiceDetails = (serviceDetails, serviceCategory) => {
  const vaccineList = serviceDetails.map((service) => ({
    name: service.msemr_name,
    label: service.smvs_label,
    value: service.msemr_healthcareserviceid,
    dayGap: service.smvs_vaccinedaygap,
    negativeBuffer: service.smvs_negativebuffer || 0,
    positiveBuffer: service.smvs_positive_buffer || 0,
    notice: service.smvs_service_information,
    firstAppointmentStatus: service.smvs_default_status_for_first_appt,
    secondAppointmentStatus: service.smvs_default_status_for_second_appt,
    serviceCategoryName: serviceCategory.msemr_name,
    serviceCategoryId: serviceCategory.msemr_healthcareservicecategoryid,
    serviceRegimenDose: service.smvs_regimendose,
    serviceRegimenType: serviceCategory.smvs_regimen_type,
    serviceViewType: getViewTypeByRegimen(
      service.smvs_regimendose,
      serviceCategory.smvs_regimen_type,
    ),
    pricing: service.smvs_pricing,
    isPayAtTimeOfService: service.smvs_out_of_pocket,
    outOfPocketText: service.smvs_out_of_pocket_text,
    immunocompromisedStatus: service.smvs_immunocompromised_status,
    minAgeRequirement: service.smvs_age_limit,
    maxAgeRequirement: service.smvs_maximum_age_limit,
    defaultStatus: service.smvs_default_status,
    minAgeMessage: service.smvs_age_limit_validation_info,
  }));

  const sortedSubservices = sortObjectArraybyStringField(vaccineList, 'name');

  return sortedSubservices;
};

export const mapServiceCategories = (serviceCategories) => {
  const serviceList = serviceCategories.map((serviceCategory) => ({
    serviceCategoryId: serviceCategory.msemr_healthcareservicecategoryid,
    serviceName: serviceCategory.msemr_name,
    notice: serviceCategory.smvs_service_information,
    services: mapServiceDetails(
      serviceCategory.healthCareServices,
      serviceCategory,
    ),
    isInsuranceEnabled: serviceCategory.smvs_insurance,
    isNoInsuranceEnabled: serviceCategory.smvs_no_insurance,
    waitlistStatus: serviceCategory.smvs_waitlist,
    factsheetUrl: serviceCategory.smvs_factsheet_url,
    typeOfServiceCode: serviceCategory?.smvs_service_code,
    serviceConfirmationMessage: serviceCategory?.smvs_confirmation_message,
  }));

  const sortedServices = sortObjectArraybyStringField(
    serviceList,
    'serviceName',
  );

  return sortedServices;
};

export const getViewTypeByRegimen = (
  serviceRegimenDose,
  serviceRegimenType,
) => {
  if (serviceRegimenType === regimenTypes.ONE_DOSE_SERIES) {
    return regimenViewType.SINGLE_DOSE_VIEW;
  } else if (serviceRegimenType === regimenTypes.TWO_DOSE_SERIES) {
    if (
      serviceRegimenDose === regimentDose.FIRST_DOSE_ONLY ||
      serviceRegimenDose === regimentDose.SECOND_DOSE_ONLY
    ) {
      return regimenViewType.SINGLE_DOSE_VIEW;
    } else {
      return regimenViewType.DOUBLE_DOSE_VIEW;
    }
  }
};

export const getDoseTypeByHealthcareService = (serviceId, allServiceList) => {
  const service = allServiceList.find((service) => service.value === serviceId);
  if (!service) return;

  const { serviceRegimenDose, serviceRegimenType } = service;
  const viewType = getViewTypeByRegimen(serviceRegimenDose, serviceRegimenType);

  return viewType;
};

/**
 * Get a list of day gap that is eligible for a vaccine type.
 *
 * @param {object} vaccineDetails : Vaccine object
 * @returns : array of list of day gap that is eligible
 */
export function getDayDifferenceArray(vaccineDetails) {
  const dayGap = vaccineDetails.dayGap ? vaccineDetails.dayGap : 0;
  const positiveBuffer = vaccineDetails.positiveBuffer
    ? vaccineDetails.positiveBuffer
    : 0;
  const negativeBuffer = vaccineDetails.negativeBuffer
    ? vaccineDetails.negativeBuffer
    : 0;

  let differenceArray = [];
  differenceArray.push(dayGap);
  // Adding negative buffer days
  for (let i = 1; i <= negativeBuffer; i++) {
    differenceArray.push(dayGap - i);
  }
  // Adding positive buffer days
  for (let i = 1; i <= positiveBuffer; i++) {
    differenceArray.push(dayGap + i);
  }
  return differenceArray;
}
