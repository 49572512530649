import React, { useCallback, useMemo, useRef } from 'react';
import Button from './Button';
import { getString } from '../../util/lang';
import {
  DISABLING_TIMER_VALUE,
  ELIGIBILITY_VERIFICATION_STATUS,
  INSURANCE_ELIGIBILITY_VALIDATION_COUNT,
} from '../../constants/insuranceEligibility';
import AlertMessage from '../COVID/common/AlertMessage';
import { getTimeFromMinuteAndSeconds } from '../../util/DateAndTime';

export const InsuranceVerificationButton = ({
  insuranceProps,
  insuranceInfo,
}) => {
  const {
    isValidateInsuranceEligibilitySet,
    insuranceValidationPayload,
    previousValidationPayload,
    selectedPayer,
    isInsuranceValidationLoading,
    patientInfo,
    isInsuranceEligible,
    insuranceEditParam,
    isInsuranceEligibilitySuccess,
    validateInsuranceEligibilityOfAppointment,
  } = insuranceProps;

  const isMedicareInsurance = useMemo(() => {
    return (
      selectedPayer && selectedPayer.label.toLowerCase().includes('medicare')
    );
  }, [selectedPayer]);

  const isPayloadChanged = !(
    JSON.stringify(insuranceValidationPayload) ===
    JSON.stringify(previousValidationPayload)
  );

  const [timer, setTimer] = React.useState(DISABLING_TIMER_VALUE);
  const [isEligibilityEnabled, setIsEligibilityEnabled] = React.useState(true);
  const [selectedCompanyLabel, setSelectedCompanyLabel] = React.useState(
    selectedPayer?.label,
  );
  const [eligibilityRequestCount, setEligibilityRequestCount] =
    React.useState(0);
  const [
    eligibilityInfoMessageAfterTimer,
    setEligibilityInfoMessageAfterTimer,
  ] = React.useState(false);

  const isOtherInsuranceLabel = (selectedLabel) => {
    if (!selectedLabel) return false;
    return (
      selectedLabel.toLowerCase() === 'other' ||
      selectedLabel.toLowerCase() === 'others'
    );
  };

  const isOtherInsuranceNameFilled = isOtherInsuranceLabel(selectedCompanyLabel)
    ? !!insuranceInfo.otherInsuranceCompany
    : true;

  const addInsuranceInfoForPharmacyInsurance = useCallback(() => {
    if (!!selectedPayer?.isPharmacyInsurance || isMedicareInsurance) {
      insuranceInfo.rxBin = insuranceInfo?.rxBin || '';
      insuranceInfo.rxPCN = insuranceInfo?.rxPCN || '';
      insuranceInfo.rxGroup = insuranceInfo?.rxGroup || '';
      if (isMedicareInsurance)
        insuranceInfo.medicareID = insuranceInfo?.medicareID || '';
      if (isMedicareInsurance)
        insuranceInfo.medicareIssuer = insuranceInfo?.medicareIssuer || '';
    } else {
      delete insuranceInfo?.medicareID;
      delete insuranceInfo?.rxBin;
      delete insuranceInfo?.rxPCN;
      delete insuranceInfo?.rxGroup;
      delete insuranceInfo?.medicareIssuer;
    }
  }, [selectedPayer, isMedicareInsurance]);

  React.useEffect(() => {
    const selectedCompanyLabel =
      selectedPayer?.label === 'Other' ? selectedPayer.label : '';
    addInsuranceInfoForPharmacyInsurance();
    setSelectedCompanyLabel(selectedCompanyLabel);
  }, [selectedPayer]);

  const timerId = useRef();
  const startTimer = useCallback(() => {
    if (timerId.current) {
      clearInterval(timerId.current);
    }

    const endTime = Date.now() + DISABLING_TIMER_VALUE;
    timerId.current = setInterval(function () {
      const remainingMs = endTime - Date.now();

      if (
        remainingMs <= 0 ||
        isInsuranceEligible === ELIGIBILITY_VERIFICATION_STATUS.ALL_VALID
      ) {
        clearInterval(timerId.current);
        setEligibilityRequestCount(0);
        if (remainingMs <= 0) setEligibilityInfoMessageAfterTimer(true);
        setTimer(DISABLING_TIMER_VALUE);
        setIsEligibilityEnabled(true);
      } else {
        setTimer(remainingMs);
      }
    }, 250);
  }, [isInsuranceEligible]);

  React.useEffect(() => {
    if (eligibilityRequestCount === 1) startTimer();
    if (
      eligibilityRequestCount === INSURANCE_ELIGIBILITY_VALIDATION_COUNT &&
      isInsuranceEligible === ELIGIBILITY_VERIFICATION_STATUS.ALL_INVALID
    )
      setIsEligibilityEnabled(false);
  }, [eligibilityRequestCount, isInsuranceEligible, startTimer]);

  React.useEffect(() => {
    if (!isInsuranceEligibilitySuccess) setIsEligibilityEnabled(true);
  }, [isInsuranceEligibilitySuccess]);

  const isAllRequiredInfoFilled =
    Object.entries(insuranceValidationPayload).every(
      ([key, element]) =>
        key === 'patientMiddleName' ||
        !(element === null || element === '' || element === undefined),
    ) &&
    isOtherInsuranceNameFilled &&
    patientInfo.birthDay &&
    patientInfo.birthYear &&
    patientInfo.birthMonth &&
    patientInfo.gender;

  const seconds = Math.floor((timer / 1000) % 60);
  const minutes = Math.floor((timer / 1000 / 60) % 60);
  const timeRemaining = <b>{getTimeFromMinuteAndSeconds(minutes, seconds)}</b>;

  const validateEligibility = async () => {
    setEligibilityRequestCount((oldCount) => oldCount + 1);
    await validateInsuranceEligibilityOfAppointment();
  };

  const insuranceEditParamMessages = useMemo(() => {
    const issues = insuranceEditParam.reduce((words, param, idx, arr) => {
      const paramMessage = (
        <b>
          {{
            patientName: getString('name'),
            insuredName: getString('primaryCardHolderHeading'),
            dob: getString('dob'),
            relation: getString('subscriberRelationship'),
            policyNumber: getString('memberId'),
          }[param] ?? param}
        </b>
      );
      if (idx === 0) {
        return [paramMessage];
      }

      if (idx === arr.length - 1) {
        return [...words, ' ', getString('and'), ' ', paramMessage];
      }

      return [...words, ', ', paramMessage];
    }, []);

    const messageParts = [
      getString('insuranceEligibleParamMessage', <>{issues}</>),
    ];

    if (insuranceEditParam.includes('policyNumber')) {
      messageParts.push(
        '.',
        <div style={{ height: '1em' }} />,
        getString('insuranceEligibilityEnsureValidPolicyNumber'),
      );
    }

    const messages = [<>{messageParts}</>];

    return messages;
  }, [insuranceEditParam]);

  return (
    <>
      <AlertMessage
        type="success"
        className={'col-12 mt-5x p-3x'}
        message={<span>{getString('isEligibleInsuranceMessage')}</span>}
        isVisible={
          !isPayloadChanged &&
          !isInsuranceValidationLoading &&
          isInsuranceEligible === ELIGIBILITY_VERIFICATION_STATUS.ALL_VALID &&
          isInsuranceEligibilitySuccess
        }
        dataqa="is-eligible-insurance-message"
      />
      <AlertMessage
        type="danger"
        className={'col-12 mt-5x p-3x'}
        message={
          <span>
            {getString(
              'isNotEligibleInsuranceMessage',
              <b>{getString('payAtTimeOfService')}</b>,
            )}
          </span>
        }
        isVisible={
          !isPayloadChanged &&
          !isInsuranceValidationLoading &&
          isInsuranceEligible === ELIGIBILITY_VERIFICATION_STATUS.ALL_INVALID &&
          !insuranceEditParam.length &&
          isInsuranceEligibilitySuccess
        }
        dataqa="is-not-eligible-insurance-message"
      />

      {!isPayloadChanged &&
        !isInsuranceValidationLoading &&
        isInsuranceEligible === ELIGIBILITY_VERIFICATION_STATUS.ALL_INVALID &&
        isInsuranceEligibilitySuccess &&
        insuranceEditParamMessages.map((message) => (
          <AlertMessage
            type="danger"
            className={'col-12 mt-5x p-3x'}
            message={<span>{message}</span>}
            isVisible={
              !isPayloadChanged &&
              !isInsuranceValidationLoading &&
              isInsuranceEligible ===
                ELIGIBILITY_VERIFICATION_STATUS.ALL_INVALID &&
              !!insuranceEditParam.length &&
              isInsuranceEligibilitySuccess
            }
            dataqa="is-not-eligible-insurance-message-with-param"
          />
        ))}

      <AlertMessage
        type="danger"
        className={'col-12 mt-5x p-3x'}
        message={
          <span>
            {getString(
              'insuranceValidationTimeRemainingMessage',
              timeRemaining,
            )}
          </span>
        }
        isVisible={
          eligibilityRequestCount >= INSURANCE_ELIGIBILITY_VALIDATION_COUNT &&
          isInsuranceEligible === ELIGIBILITY_VERIFICATION_STATUS.ALL_INVALID &&
          !isInsuranceValidationLoading
        }
        dataqa="is-not-eligible-insurance-message-with-timer"
      />

      <AlertMessage
        type="info"
        className={'col-12 mt-5x p-3x'}
        message={
          <span>
            {getString(
              'timeUpInsuranceMessage',
              <span>
                <b>{getString('checkEligibility')}</b>
              </span>,
            )}
          </span>
        }
        isVisible={
          eligibilityInfoMessageAfterTimer &&
          eligibilityRequestCount === 0 &&
          isInsuranceEligible === ELIGIBILITY_VERIFICATION_STATUS.ALL_INVALID
        }
        dataqa="is-eligible-insurance-message-after-timer"
      />
      {isValidateInsuranceEligibilitySet && (
        <div className="mt-5x d-flex justify-content-end">
          <Button
            isEnabled={
              isEligibilityEnabled &&
              isAllRequiredInfoFilled &&
              isPayloadChanged &&
              !isInsuranceValidationLoading &&
              !isOtherInsuranceLabel(selectedCompanyLabel)
            }
            label={
              isInsuranceValidationLoading
                ? getString('insuranceEligibilityLoading')
                : getString('checkEligibility')
            }
            onClick={validateEligibility}
            className="btn btn-primary mt-6x mt-0x-sm"
            dataqa="validate-eligibility-button"
          />
        </div>
      )}
    </>
  );
};
