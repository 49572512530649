import React from 'react';
import _ from 'lodash';

import {
  DetailContext,
  useGroupAppointmentContext,
  usePatientInfo,
} from 'context';
import { withRouter } from 'react-router';
import Router from '../../Routes/RouterRegisterer';

import { CovidApp } from './CovidApp';
import { handleError } from '../../util/errorHandler';
import {
  formatLocationDetailForState,
  getAllServices,
} from 'services/services';
import * as appointmentService from 'services/appointment';
import * as clinicDayService from '../../services/clinicDay';
import * as eligibilityQuestionService from 'services/eligibilityQuestions';
import * as screeningService from 'services/screening';
import * as localizedWebContentsService from 'services/localizedWebContents';
import {
  getUniqueArrayOfLiterals,
  mergeArryaOfObjects,
  replaceArrayElementByIndex,
  sortObjectArraybyField,
} from 'util/array';
import * as insuranceOrganizationService from 'services/insuranceOrganization';
import { getLanguageIdByLanguageCode, getString } from '../../util/lang';
import APP_LANGUAGES from '../../constants/appLanguages';
import {
  convertUTCToDateObject,
  extractTimeFromDateObject,
  toStandardDateFormat,
} from '../../util/DateAndTime';
import * as clinicService from '../../services/clinic';
import * as toast from '../../util/toast';
import { ServiceGroupMap } from 'constants/clinicAndTimingActions';
import { dashMobileFormat } from 'util/TextFormat';
import DateTimeUtils from 'util/DateAndTime';
import { waitlistPatient } from 'services/waitlist';
import { AvailableInsuranceCoverageIndices } from '../../constants/patientInfo';

class App extends CovidApp {
  setSelectedServices = (serviceList) => {
    this.setState({ selectedServices: serviceList });
  };

  setFollowupConfirmationInfo = (followupConfirmationInfo) => {
    this.setState({ followupConfirmationInfo });
  };

  fetchServices = async (
    languageId,
    organizationId,
    skipScheduleCheck = false,
  ) => {
    try {
      this.setState({ isServiceListFetching: true });
      const { serviceTypes, services, subServices } = await getAllServices(
        this.state.defaultLanguageId || languageId,
        organizationId,
        skipScheduleCheck,
      );
      //merge serviceTypes / services / subservices list if the language is changed, hence no missing service if no translation

      if (languageId !== this.state.defaultLanguageId) {
        const {
          serviceTypes: otherLangServiceTypes,
          services: otherLangServices,
          subServices: otherLangSubservices,
        } = await getAllServices(languageId, organizationId);

        serviceTypes.allIds.forEach((id) => {
          if (otherLangServiceTypes.allIds.includes(id))
            serviceTypes.byId[id].name = otherLangServiceTypes.byId[id].name;
        });
        services.allIds.forEach((id) => {
          if (otherLangServices.allIds.includes(id))
            services.byId[id].name = otherLangServices.byId[id].name;
        });
        subServices.allIds.forEach((id) => {
          if (otherLangSubservices.allIds.includes(id))
            subServices.byId[id].name = otherLangSubservices.byId[id].name;
        });
      }

      const serviceState = {
        serviceTypes: { byId: serviceTypes.byId, allIds: serviceTypes.allIds },
        services: { byId: services.byId, allIds: services.allIds },
        subServices: { byId: subServices.byId, allIds: subServices.allIds },
      };

      this.setState({ ...serviceState });

      return serviceState;
    } catch (e) {
      handleError(e);
    } finally {
      this.setState({ isServiceListFetching: false });
    }
  };

  /**
   * Fetch clinic days if it is not present.
   *
   * @param {object} clinic : Previous selected clinic.
   */
  // TODO - move this logic to index.js
  fetchClinicDayForClinic = async (
    locationId,
    silentLoading = false,
    subServiceIds = this.state.selectedVaccine.value,
    clinicTimeGap,
    clinicalServiceCode,
  ) => {
    if (!locationId) return;
    if (!silentLoading) {
      this.setDateTimeLoading(true);
      this.setState({
        clinicToClinicDays: {
          ...this.state.clinicToClinicDays,
          [locationId]: [],
        },
      });
    }

    try {
      const minDate =
        this.state.appointments?.[0]?.firstAppointment?.minRescheduleDate;
      const clinicDays = await clinicDayService.getClinicDaysByClinicId(
        locationId,
        subServiceIds,
        clinicTimeGap,
        clinicalServiceCode,
        minDate,
      );

      clinicDays.sort((a, b) => {
        return new Date(a.clinicDate) - new Date(b.clinicDate);
      });
      this.setState((state) => ({
        clinicToClinicDays: {
          ...state.clinicToClinicDays,
          [locationId]: clinicDays,
        },
      }));
      this.setDateTimeLoading(false);

      return clinicDays;
    } catch (err) {
      handleError(err);
      this.setDateTimeLoading(false);
    }
  };

  fetchLocalizedWebContents = async (languageId) => {
    this.setState({ isLocalizedWebContentsFetching: true });
    const { selectedServices, services } = this.state;
    const selectedServiceObjList = selectedServices.map((serv) => {
      return {
        id: serv.serviceId,
        name: services.byId[serv.serviceId].name,
        factsheetUrl: services.byId[serv.serviceId].factsheetUrl,
      };
    });
    try {
      const localizedWebContents = await Promise.all(
        selectedServiceObjList.map(async (service) => {
          const localizedWebContent =
            await localizedWebContentsService.fetchLocalizedWebContents(
              languageId,
              service.id,
            );
          localizedWebContent.termsAndConditionsAcceptance = (
            localizedWebContent.termsAndConditionsAcceptance || []
          ).map((tac) => {
            tac.id = `${tac.id}_${service.id}`;
            return tac;
          });
          return localizedWebContent;
        }),
      );

      const uniqueLocalizedWebContents = [
        ...new Map(localizedWebContents.map((lwc) => [lwc.id, lwc])).values(),
      ];
      const finalLocalizedWebContents =
        localizedWebContentsService.addFactSheetUrlToTAC(
          uniqueLocalizedWebContents,
          selectedServiceObjList,
        );

      this.setState({
        localizedWebContents: finalLocalizedWebContents,
      });
    } catch (err) {
      handleError(err);
    } finally {
      this.setState({ isLocalizedWebContentsFetching: false });
    }
  };

  fetchEligibilityQuestions = async (languageId) => {
    this.setState({
      isEligibilityQuestionFetching: true,
    });
    const { selectedServices, defaultLanguageId } = this.state;
    const selectedSubServiceIds = selectedServices.map(
      (serv) => serv.subServiceId,
    );
    try {
      const eligibilityQuestions = await Promise.all(
        selectedSubServiceIds.map(async (subId) => {
          const response =
            await eligibilityQuestionService.fetchEligibilityQuestions(
              languageId,
              subId,
            );
          let defaultLanguageQuestions = [];
          if (defaultLanguageId !== languageId) {
            defaultLanguageQuestions =
              await eligibilityQuestionService.fetchEligibilityQuestions(
                defaultLanguageId,
                subId,
              );
          }
          const finalResponse = !!defaultLanguageQuestions.length
            ? mergeArryaOfObjects(defaultLanguageQuestions, response, 'id')
            : response;
          const sortedEligibilityQuestions = sortObjectArraybyField(
            finalResponse,
            'order',
          );

          return sortedEligibilityQuestions;
        }),
      );

      this.setState({
        eligibilityQuestions,
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isEligibilityQuestionFetching: false });
    }
  };

  fetchScreeningQuestions = async (languageId) => {
    this.setState({
      isScreeningQuestionFetching: true,
    });
    const { selectedServices, defaultLanguageId } = this.state;
    const selectedSubServiceIds = selectedServices.map(
      (serv) => serv.subServiceId,
    );
    try {
      const screeningQuestions = await Promise.all(
        selectedSubServiceIds.map(async (subId) => {
          const response = await screeningService.fetchScreeningQuestions(
            languageId,
            subId,
          );
          let defaultLanguageQuestions = [];
          if (defaultLanguageId !== languageId) {
            defaultLanguageQuestions =
              await screeningService.fetchScreeningQuestions(
                defaultLanguageId,
                subId,
              );
          }
          const finalResponse = !!defaultLanguageQuestions.length
            ? mergeArryaOfObjects(defaultLanguageQuestions, response, 'id')
            : response;

          const sortedScreeningQuestions = sortObjectArraybyField(
            finalResponse,
            'order',
          );

          return sortedScreeningQuestions;
        }),
      );

      this.setState({
        screeningQuestions,
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isScreeningQuestionFetching: false });
    }
  };

  _initialFetchPayerList = async () => {
    try {
      const payerlist = await this.fetchPayerList(); // all possible payer list regardless of insuranceType are fetched

      if (!payerlist) {
        // may be undefined in some case
        return;
      }

      const insuranceTypeSet = payerlist.reduce((insuranceTypeSet, payer) => {
        for (const insuranceType of payer.insuranceType) {
          insuranceTypeSet.add(insuranceType);
        }
        return insuranceTypeSet;
      }, new Set());

      const availableInsuranceTypes = [...insuranceTypeSet.values()];
      this.setState({ availableInsuranceTypes });
    } catch (err) {
      // dont throw error
      console.error(err);
    }
  };

  fetchPayerList = async (
    insuranceType,
    insuranceIndex = AvailableInsuranceCoverageIndices.primaryInsuranceIndex,
  ) => {
    if (this.state.payerList.length === 0 || !!insuranceType) {
      try {
        const serviceIds = this.state.selectedServices
          .filter((selection) => {
            // only fetch payers for services where insurance is enabled
            const subService =
              this.state.subServices.byId[selection.subServiceId];
            return subService && subService.isInsuranceEnabled;
          })
          .map((selection) => selection.serviceId)
          .filter((i) => i);

        const payerlist = await insuranceOrganizationService.fetchPayerList(
          serviceIds,
          insuranceType,
        );

        const mappedPayerlist = payerlist.map(
          ({ name, id, enableEligibility, guid, isPharmacyInsurance }) => {
            return {
              label: name,
              value: guid,
              guid,
              id,
              isPharmacyInsurance,
              enableEligibility,
            };
          },
        );

        this.setPayerlist(mappedPayerlist, insuranceIndex);
        return payerlist;
      } catch (e) {
        handleError(e);
      }
    }
  };
  /**
   * Set insuranceVerified value after validation of insurance info
   * @param {{bool}} val
   */
  setInsuranceCoverageList = (val) => {
    this.setState({ insuranceCoverageList: val });
  };

  _createInitialAppointmentObject = (appointment, locationIds) => {
    if (appointment) {
      const location = locationIds.find(
        (location) => location === appointment.locationId,
      ); // if the appointment exists in currently available location

      if (!location) {
        handleError(null, {
          message: getString('clinicNotFoundForAppointment'),
        });

        this.setState({
          allowReschedule: false,
        });
      }

      appointment.venueCode = location || {};

      return appointment;
    }

    this.setState({
      allowReschedule: false,
    });

    return appointment;
  };

  // TODO: move rescheduleDataSetup to hooks and remove setLocations
  setLocations = (details) => {
    const locationState = { location: formatLocationDetailForState(details) };
    this.setState(locationState);

    return locationState.location;
  };

  /**
   * Fetch services, localized web contents, screening questions and eligiblity questions
   * Fetch organization details, if organization specific
   * Fetch appointment details required for reshceduling
   * Set processed values to context
   *
   * @param {string} organizationId : organization id
   * @param {string} appointmentId : appointment id
   */
  rescheduleDataSetup = async (
    organizationId,
    appointmentId,
    skipScheduleCheck = false,
  ) => {
    try {
      // if (!this.state.setupPhase) return;
      this.setLoading(true);

      const convertToAppointmentObj = (appointment) => {
        return {
          date: convertUTCToDateObject(appointment.appointmentDateTime),
          time: extractTimeFromDateObject(appointment.appointmentDateTime),
          activityid: appointment.activityId,
          fulfilled: appointment.fulfilled,
          cancelled: appointment.cancelled,
          noShow: appointment.noShow,
          completed: appointment.completed,
          appointmentUUID: appointment.appointmentUUID,
          canBeRescheduled: !(
            appointment.noShow ||
            appointment.fulfilled ||
            appointment.cancelled
          ),
          minRescheduleDate: toStandardDateFormat(
            appointment.minRescheduleDate,
          ),
          appointmentStatus: appointment.appointmentStatus,
          groupAppointmentId: appointment.groupAppointmentId,
        };
      };

      // Setting up language id
      const languageCode = this.getCurrentLanguageCode();
      this.updateSelectedLanguageCode(languageCode);
      const selectedLanguageId = getLanguageIdByLanguageCode(
        languageCode,
        this.state.appSettings.languages,
      );
      const defaultLanguageId = getLanguageIdByLanguageCode(
        APP_LANGUAGES.english,
        this.state.appSettings.languages,
      );

      const serviceStatePromise = this.fetchServices(
        selectedLanguageId,
        organizationId,
        skipScheduleCheck,
      );

      // Fetch appointment and organization details
      const fetchAppointmentPromise = this.fetchAppointment(appointmentId);

      const fetchOrganizationPromise =
        this.fetchOrganizationDetail(organizationId);
      const [serviceState, appointments, organizationDetails] =
        await Promise.all([
          serviceStatePromise,
          fetchAppointmentPromise,
          fetchOrganizationPromise,
        ]);

      let subServiceIds = getUniqueArrayOfLiterals(
        appointments.map((eachAppointment) => eachAppointment.subServiceId),
      );

      const serviceTypes = subServiceIds
        .map((subServiceId) => {
          const serviceTypeId =
            serviceState.subServices.byId[subServiceId]?.serviceTypeId;

          return serviceState.serviceTypes.byId[serviceTypeId];
        })
        .filter((i) => i);

      const locationFetchSubServiceIds =
        serviceTypes[0]?.serviceGroup === ServiceGroupMap.counselling
          ? [subServiceIds[0]]
          : subServiceIds;

      const locations = this.setLocations(
        await clinicService.getAllLocationsFromSubScheduling(
          locationFetchSubServiceIds,
          organizationId,
          skipScheduleCheck,
        ),
      );

      this._initialFetchPayerList();

      const selectedLocationId =
        appointments[0].locationId || appointments[1].locationId;

      // Setting up appointment objects; max appointments is explicitly equal to 2
      // const [firstAppointment, secondAppointment] = appointments.map((appointment) =>
      //   this._createInitialAppointmentObject(appointment, locations.allIds),
      // );

      let allowReschedule = true;
      appointments.forEach((appointment) => {
        if (!locations.allIds.includes(appointment.locationId)) {
          console.error('Location not found.');
          allowReschedule = false;
        }
      });

      const allAppointments = appointments.map((appointment) => {
        if (!appointment) {
          return null;
        }

        const stateAppointment = convertToAppointmentObj(appointment);
        return {
          ...stateAppointment,
          // TODO: remove first and second appointments altogether
          firstAppointment: stateAppointment,
          secondAppointment: null,
        };
      });

      if (
        !allAppointments.length ||
        !allAppointments.every((appointment) => appointment)
      ) {
        console.error(allAppointments);
        throw Error('Invalid appointments for reschedule.');
      }

      let selectedServices = [];
      subServiceIds.forEach((eachSubServiceId) => {
        const subServiceData = serviceState.subServices.byId[eachSubServiceId];
        if (!subServiceData) {
          toast.error({
            title: '',
            message: 'Service not found',
          });
          allowReschedule = false;
          return;
        }
        selectedServices.push({
          serviceId: subServiceData.serviceId,
          serviceTypeId: subServiceData.serviceTypeId,
          subServiceId: eachSubServiceId,
        });
      });

      const appointmentBeingRescheduled = appointments.find(
        (appointment) => appointment.activityId === appointmentId,
      );

      this.setState({
        rescheduleClinicId: selectedLocationId, //secondAppointment?.clinic?.msemr_locationid,
        selectedLocationId,
        organizationId,
        organizationObj: organizationDetails,
        appointmentBeingRescheduled,
        appointments: allAppointments,
        patientInfo: replaceArrayElementByIndex(
          this.state.patientInfo,
          this.state.currentPatientIndex,
          {
            ...this.state.patientInfo[this.state.currentPatientIndex],
            confirmationCode:
              appointments[0]?.confirmationCode ||
              appointments[1]?.confirmationCode,
          },
        ),
        selectedServices,
        setupPhase: false,
        firstPageLoaded: true,
        isServiceAndClinicSelected: true,
      });
      return allowReschedule;
    } catch (e) {
      // TODO: remove after another mechanism is created to avoid swallowing errors
      console.error(e);
      toast.error({
        title: '',
        message: 'Invalid appointments for rescheduling',
      });
      return false;
    } finally {
      this.setLoading(false);
    }
  };

  setServiceForFollowup = async (subserviceIds, organizationId) => {
    // Setting up language id
    const languageCode = this.getCurrentLanguageCode();
    this.updateSelectedLanguageCode(languageCode);
    const selectedLanguageId = getLanguageIdByLanguageCode(
      languageCode,
      this.state.appSettings.languages,
    );

    const serviceState = await this.fetchServices(
      selectedLanguageId,
      organizationId,
    );

    let selectedServices = [];
    subserviceIds.forEach((eachSubServiceId) => {
      const subServiceData = serviceState.subServices.byId[eachSubServiceId];
      if (!subServiceData) {
        toast.error({
          title: '',
          message: 'Service not found',
        });

        return;
      }
      selectedServices.push({
        serviceId: subServiceData.serviceId,
        serviceTypeId: subServiceData.serviceTypeId,
        subServiceId: eachSubServiceId,
      });
    });

    this.setState({ selectedServices });
  };

  confirmFollowupDataSetup = async (
    appointmentId,
    secretKey,
    organizationId,
  ) => {
    this.setLoading(true);

    try {
      const result = await appointmentService.confirmAppointmentForFollowUp(
        appointmentId,
        secretKey,
      );

      const convertToAppointmentObj = (appointment) => {
        return {
          date: convertUTCToDateObject(appointment.appointmentDateTime),
          time: extractTimeFromDateObject(appointment.appointmentDateTime),
          activityid: appointmentId,
        };
      };

      let subServiceIds = getUniqueArrayOfLiterals(
        result.map((eachAppointment) => eachAppointment.subServiceId),
      );

      await this.setServiceForFollowup(subServiceIds, organizationId);

      const locations = this.setLocations(
        await clinicService.getAllLocationsFromSubScheduling(
          subServiceIds,
          organizationId,
        ),
      );

      if (!locations.allIds.includes(result[0].locationId)) {
        toast.error({
          title: '',
          message: 'Location not found',
        });
      }

      const selectedLocationId = result[0].locationId || result[1].locationId;

      const allAppointments = result.map((appointment) => {
        return {
          // TODO: remove first and second appointments altogether
          firstAppointment: appointment
            ? convertToAppointmentObj(appointment)
            : null,
          secondAppointment: null,
        };
      });

      this.setFollowupConfirmationInfo({ appointmentStatus: 'BOOKED' });
      this.setState({
        selectedLocationId: selectedLocationId,
        appointments: allAppointments,
        patientInfo: {
          ...this.state.patientInfo,
          confirmationCode: result[0]?.confirmationCode,
        },
      });
    } catch (e) {
      if (e?.response?.data?.data) {
        const subserviceIds = e?.response?.data?.data.map(
          (info) => info.subServiceId,
        );
        const appointmentStatus = e?.response?.data?.data[0]?.appointmentStatus;
        await this.setServiceForFollowup(subserviceIds, organizationId);
        this.setFollowupConfirmationInfo({ appointmentStatus });
      } else {
        console.log(e);
      }
    } finally {
      this.setLoading(false);
    }
  };

  setRescheduleAppointment = (activityId) => {
    const { firstAppointment, secondAppointment } = this.state.appointments[0];

    const appointment = [firstAppointment, secondAppointment].find(
      (appointment) => appointment?.activityid === activityId,
    );

    return this.setState({
      reschedule: true,
      appointmentBeingRescheduled: appointment ? appointment : {},
    });
  };

  /**
   * Fetch appointment data using appointment id.
   *
   * @param {string} appointmentId : Appointment Id.
   */
  fetchAppointment = async (appointmentId) => {
    if (!appointmentId) return [];

    try {
      // HACK: in some cases, this is called before the state has been set
      // so doing a 0ms sleep to give time for state to update
      if (!this.state.security.token) {
        await new Promise((resolve) => setTimeout(resolve, 0));
      }
      return await appointmentService.getAppointmentById(
        appointmentId,
        this.state.security.token,
      );
    } catch (err) {
      handleError(err);

      return [];
    }
  };

  getContactByAppointmentId = async (appointmentId) => {
    try {
      this.setLoading(true);
      const patientInfo = await appointmentService.getContactByAppointmentId(
        appointmentId,
        this.state.security.token,
      );

      const {
        patientInfo: statePatientInfo,
        patientRecordInfo: statePatientRecordInfo,
        currentPatientIndex,
      } = this.state;

      const getDobInfo = () => {
        if (!patientInfo?.birthdate) return {};
        const [birthYear, birthMonth, birthDay] =
          patientInfo.birthdate.split('-');

        return {
          birthDay: birthDay ? parseInt(birthDay, 10).toString() : '',
          birthMonth: birthMonth || '',
          birthYear: birthYear || '',
        };
      };

      const currentPatientInfo = statePatientInfo[currentPatientIndex];
      const currentPatientRecordInfo =
        statePatientRecordInfo[currentPatientIndex];

      let updatedPatientInfo = [...statePatientInfo];
      updatedPatientInfo[currentPatientIndex] = {
        ...currentPatientInfo,
        firstName: patientInfo.firstname || '',
        lastName: patientInfo.lastname || '',
        middleName: patientInfo.middlename || '',
        zipCode: patientInfo.postalCode || '',
        gender: patientInfo.gendercode ? patientInfo.gendercode.toString() : '',
        mobileNo: dashMobileFormat(patientInfo.mobilephone) || '',
        email: patientInfo.email || '',
        confirmEmail: patientInfo.email || '',
        homeAddress: patientInfo.address || '',
        state: patientInfo.state || '',
        city: patientInfo.city || '',
        county: patientInfo.county || '',
        race: patientInfo.race ? patientInfo.race.toString() : '',
        ethnicity: patientInfo.ethnicity
          ? patientInfo.ethnicity.toString()
          : '',
        primaryLanguage: patientInfo.language
          ? patientInfo.language.toString()
          : '',
        ...getDobInfo(),
      };

      let updatedPatientRecordInfo = [...statePatientRecordInfo];
      updatedPatientRecordInfo[currentPatientIndex] = {
        ...currentPatientRecordInfo,
        patientId: patientInfo?.patient || '',
      };

      this.setDetails({
        patientInfo: updatedPatientInfo,
        patientRecordInfo: updatedPatientRecordInfo,
      });
    } catch (err) {
      handleError(err);

      return [];
    } finally {
      this.setLoading(false);
    }
  };

  setSecurityToken = (token) => {
    this.setState((state) => ({ security: { ...state.security, token } }));
  };

  waitlistPatient = async () => {
    const { currentPatientIndex } = this.state;
    const currentPatientInfo = this.state.patientInfo[currentPatientIndex];

    const patientInfo = {
      firstname: currentPatientInfo.firstName,
      lastname: currentPatientInfo.lastName,
      middlename: currentPatientInfo.middleName,
      age: DateTimeUtils.getAgeFromDate(
        currentPatientInfo.birthDay,
        currentPatientInfo.birthMonth,
        currentPatientInfo.birthYear,
      ),
      gendercode: currentPatientInfo.gender,
      zipcode: currentPatientInfo.zipCode,
      phoneNumber: currentPatientInfo.mobileNo.replace(/-/g, ''),
      email: currentPatientInfo.email,
      race: currentPatientInfo.race,
      ethnicity: currentPatientInfo.ethnicity,
      guardianFirstname: currentPatientInfo.guardianFirstName,
      guardianLastname: currentPatientInfo.guardianLastName,
      guardianMiddlename: currentPatientInfo.guardianMiddleName,
      associatedOrganizationId: this.state.organizationId,
    };

    const eligibilityResponse = this.state.eligibilityQuestions.map(
      (questions) =>
        questions.map((question) => ({
          id: question.id,
          value:
            this.state.eligibilityResponse[currentPatientIndex][question.id],
        })),
    );

    const screeningAnswers = this.state.screeningQuestions.map((questions) =>
      questions.map((question) => ({
        id: question.id,
        value:
          this.state.patientHealthAnswers[currentPatientIndex][question.id]
            ?.value,
        additionalInfo:
          this.state.patientHealthAnswers[currentPatientIndex][question.id]
            ?.additionalInfo,
      })),
    );

    const associatedSubServiceIds = this.state.selectedServices.map(
      (serv) => serv.subServiceId,
    );

    await waitlistPatient({
      patientInfo,
      eligibilityResponse: _.uniqBy(eligibilityResponse.flat(), 'id'),
      screeningAnswers: _.uniqBy(screeningAnswers.flat(), 'id'),
      associatedSubServiceIds,
    });
  };

  setIndividualReschedule = (value) => {
    this.setState({
      isIndividualReschedule: !!value,
    });
  };

  render() {
    return (
      <DetailContext.Provider
        value={{
          details: this.state,
          setDetails: this.setDetails,
          normalFlow: this.state.normalFlow,
          setNormalFlow: this.setNormalFlow,
          getAppSettings: this.getAppSettings,
          pageComplete: this.state.pageComplete,
          setPageComplete: this.setPageComplete,
          preServiceDataSetup: this.preServiceDataSetup,
          postServiceDataSetup: this.postServiceDataSetup,
          rescheduleDataSetup: this.rescheduleDataSetup,
          waitlistDataSetup: this.waitlistDataSetup,
          onHealthQuestionAnswerChange: this.onHealthQuestionAnswerChange,
          onPreScreeningAnswersChange: this.onPreScreeningAnswersChange,
          fetchPayerList: this.fetchPayerList,
          getPayerlist: this.getPayerlist,
          // setInsuranceVerified: this.setInsuranceVerified,
          setInsuranceCoverageList: this.setInsuranceCoverageList,
          submitAppointment: this.submitAppointment,
          disableNavForSuccessPage: this.disableNavForSuccessPage,
          setLoading: this.setLoading,
          resetForNewPatient: this.resetForNewPatient,
          resetPageComplete: this.resetPageComplete,
          setRescheduleAppointment: this.setRescheduleAppointment,
          onSelfDeclarationChange: this.onSelfDeclarationChange,
          handleAgreement: this.handleAgreement,
          handleFirstAgreement: this.handleFirstAgreement,
          fetchClinicDayForClinic: this.fetchClinicDayForClinic,
          saveEligibilityResponse: this.saveEligibilityResponse,
          fetchAppConfigurations: this.fetchAppConfigurations,
          fetchStatesAndCounties: this.fetchStatesAndCounties,
          setSelectedVaccine: this.setSelectedVaccine,
          setSelectedVaccineService: this.setSelectedVaccineService,
          isSecurityChecked: this.state.isSecurityChecked,
          setSecurityChecked: this.setSecurityChecked,
          updateContentWithSelectedLanguage:
            this.updateContentWithSelectedLanguage,
          updateSelectedLanguageCode: this.updateSelectedLanguageCode,
          registerUser: this.registerUser,
          setSelectedService: this.setSelectedService,
          setFilteredSubservice: this.setFilteredSubservice,
          setSelectedVaccineForService: this.setSelectedVaccineForService,
          setLocationSearchTerm: this.setLocationSearchTerm,
          setSelectedClinic: this.setSelectedClinic,
          setIsServiceAndClinicSelected: this.setIsServiceAndClinicSelected,
          setClinicList: this.setClinicList,
          setAppointmentSuccess: this.setAppointmentSuccess,

          //multi-services
          fetchServices: this.fetchServices,
          setSelectedServices: this.setSelectedServices,
          fetchLocalizedWebContents: this.fetchLocalizedWebContents,
          fetchScreeningQuestions: this.fetchScreeningQuestions,
          fetchEligibilityQuestions: this.fetchEligibilityQuestions,
          confirmFollowupDataSetup: this.confirmFollowupDataSetup,
          setSecurityToken: this.setSecurityToken,

          getContactByAppointmentId: this.getContactByAppointmentId,
          waitlistPatient: this.waitlistPatient,

          //Group appointment
          setIsGroupAppointment: this.setIsGroupAppointment,
          setIndividualReschedule: this.setIndividualReschedule,
        }}
      >
        <Router />
      </DetailContext.Provider>
    );
  }
}

export default withRouter(App);
