import moment from 'moment';
import { sanitize } from 'dompurify';
import React, { useEffect, useContext } from 'react';

import { constructionImage } from '../../../../../assets/images';
import { extractTimeFromDateString } from '../../../../../util/DateAndTime';
import { getString } from '../../../../../util/lang';
import { DetailContext } from '../../../../../context';
import LanguageSelect from '../../../common/LanguageSelect';

const contactMessage = (email, phoneNo) => {
  let message = '';

  if (email) {
    message += `<span>${getString('emailUs')} <a href='mailto:${email}'>${email}</a></span>`;
  }

  if (email && phoneNo) {
    message += ` ${getString('or')} `;
  }

  if (phoneNo) {
    message += `<span>${getString('callUsAt')} <a href='tel:${phoneNo}'>${phoneNo}</a></span>`;
  }

  return { isContactInfoAvailable: email || phoneNo, message };
};

const upTimeMessage = (upTime) => {
  if (upTime) {
    const date = upTime.split('T')[0];
    const formattedDate = moment(date).format('Do MMMM');
    const formattedTime = extractTimeFromDateString(upTime);

    return `${getString('uptimeByTimeDate', formattedTime, formattedDate)}`;
  }
  return getString('asSoon');
};

const UnderMaintenance = ({ logo, email, phoneNo, upTime }) => {
  const { isContactInfoAvailable, message } = contactMessage(email, phoneNo);
  const sanitizedMessage = sanitize(message);

  const context = useContext(DetailContext);

  const { languages } = context.details.appSettings;

  useEffect(() => {
    // Falsy check for cases where we are unable to fetch languages from server but need to show the under maintenance page
    // For example when the server is down
    languages && context.updateSelectedLanguageCode();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="maintenance-page d-flex flex-column justify-content-between"
      style={{ height: '100vh' }}
    >
      {languages && <LanguageSelect />}
      <div className="maintenance-content d-flex flex-column align-items-center justify-content-center px-5x">
        <div className="maintenance-image">
          <img src={constructionImage} alt="Icon" />
        </div>
        <div className="maintenance-text text-center">
          <h1 className="text-uppercase">
            {getString(
              'underMaintenanceHeader',
              <span className="color-primary-base d-block">
                {getString('underMaintenance')}
              </span>,
            )}
          </h1>
          <p>
            {getString('upTimeMessage', upTimeMessage(upTime))}
            <br />
            {isContactInfoAvailable && (
              <>
                {getString(
                  'underMaintenanceContactInfo',
                  <span
                    dangerouslySetInnerHTML={{ __html: sanitizedMessage }}
                  />,
                )}
              </>
            )}
          </p>
        </div>
      </div>
      <div className="maintenance-logo text-center pb-15x">
        {logo && (
          <img
            src={logo}
            alt={`Organization Logo`}
            style={{ maxWidth: '200px' }}
          />
        )}
      </div>
    </div>
  );
};

export default UnderMaintenance;
