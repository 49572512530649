import ItemTable from './ItemTable/ItemTable';
import { FormSignature } from './Consent/Signature';
import PatientInfo from '../common/PatientInfo/PatientInfo';
import DeliveryInfo from '../common/DeliveryInfo/DeliveryInfo';
import PharmacyInfo from '../common/PharmaInfo/PharmacyInfo';
import { IndividualResponsible } from './Consent/IndividualResponsible';
import HomeAssessmentAndSafety from './HomeAssessmentAndSafety/HomeAssessmentAndSafety';
import Questionnaire from '../../presentation/DeliveryTicket/Questionnaire/Questionnaire';
import DocumentCheckList from '../../presentation/DeliveryTicket/DocumentCheckList/DocumentCheckList';
import ProductSettingAndTestingForm from '../../presentation/DeliveryTicket/ProductSettingAndTestingForm/ProductSettingAndTestingForm';
import ProofOfDeliveryAcknowledgement from '../../presentation/DeliveryTicket/ProofOfDeliveryAcknowledgement/ProofOfDeliveryAcknowledgement';

const DeliveryTicketForm = ({
  data,
  formErrors,
  isEdit,
  setDeliveryTicketHandler,
}) => {
  const {
    patientData,
    orderItemData,
    questionnaireData,
    documentsData,
    settingAndTestingData,
    proofOfDeliveryData,
    deliveryInfo,
    setupSignatureData,
    homeAssessmentData,
    consent = {},
  } = data || {};

  return (
    <div className="group-container">
      <PharmacyInfo className="mt-6x" />
      <div className="d-flex justify-content-between gap-4">
        <PatientInfo className="mt-4x flex-1" data={patientData} />
        <DeliveryInfo className="mt-4x flex-1" data={deliveryInfo} />
      </div>

      <ItemTable
        tableData={orderItemData}
        isEdit={isEdit}
        setDeliveryTicketHandler={setDeliveryTicketHandler}
      />

      <Questionnaire
        questionnaire={questionnaireData}
        isEdit={isEdit}
        formErrors={formErrors?.questionnaire}
        setDeliveryTicketHandler={setDeliveryTicketHandler}
      />

      <DocumentCheckList
        documentsData={documentsData}
        setDeliveryTicketHandler={setDeliveryTicketHandler}
        isEdit={isEdit}
      />

      <ProductSettingAndTestingForm
        settingAndTestingData={settingAndTestingData}
        setDeliveryTicketHandler={setDeliveryTicketHandler}
        isEdit={isEdit}
      />

      <HomeAssessmentAndSafety
        homeAssessmentData={homeAssessmentData}
        setDeliveryTicketHandler={setDeliveryTicketHandler}
        isEdit={isEdit}
      />

      <IndividualResponsible
        isEdit={true}
        setupSignatureData={setupSignatureData}
        setDeliveryTicketHandler={setDeliveryTicketHandler}
        formErrors={formErrors}
      />

      <ProofOfDeliveryAcknowledgement
        proofOfDeliveryData={proofOfDeliveryData}
        setDeliveryTicketHandler={setDeliveryTicketHandler}
        isEdit={isEdit}
        formErrors={formErrors?.proofOfDelivery}
      />

      {/* Signature are editable by both patient and staff, need to be revisted on v2 */}
      <FormSignature
        isEdit={true}
        consent={consent}
        setDeliveryTicketHandler={setDeliveryTicketHandler}
        formErrors={formErrors?.consent}
      />
    </div>
  );
};

export default DeliveryTicketForm;
